.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.test {
  background-color: maroon;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
  --grey: #2e2e2f;
  --red: #f13c4a;
  --white: #ffffff;
  --blue: #021422;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: 16px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  scroll-behavior: smooth;
}

nav {
  width: 100%;
  background-color: var(--grey);
  /* background-image: url(/public/assets/logo.png); */
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
}
.links {
  display: flex;
  justify-content: space-evenly;
  margin-right: 10%;
}
.links i {
  margin-right: 1.5rem;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}
.i-fb:hover {
  color: rgb(0, 68, 255);
}
.i-tw:hover {
  color: rgb(0, 166, 255);
}
.i-ln:hover {
  color: rgb(0, 68, 255);
}
.i-vn:hover {
  color: rgb(0, 255, 94);
}
.i-ig:hover {
  color: rgb(255, 0, 136);
}
.ham-btn {
  display: none;
  font-size: 1.5rem;
}

header {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;

  position: sticky;
  top: 0;
  padding-top: 10px;

  height: 70px;
  z-index: 60;
  padding-bottom: 10px;
  background-color: white;
}

.logo-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-left: 10%;
  margin-top: 3%;

  align-items: center;
}
.logo {
  width: 50px;
  height: 50px;
}
.logo-text {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.logo-text p {
  font-size: 1.2rem;
  font-weight: 600;
}
.logo-header {
  letter-spacing: -1px;
  font-size: 2rem;
  color: var(--red);
  -webkit-text-stroke: 2px var(--#8000);
  font-weight: 900;
}
.header-links {
  width: 50%;
  /* padding-top: 2vh; */
  /* align-items: center; */
  /* font-size: large; */
}
.num-div {
  display: flex;
  gap: 1vw;
  width: 100%;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  align-content: center;
}
.num-div-inner {
  font-size: 1.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.milestone-div {
  display: flex;
  flex-direction: column;
  gap: 10vh;
  padding: 3vw;
  align-items: center;
}
.m-div-1 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  transform: skewX(-2deg);
  padding: 2vw;
  background-image: linear-gradient(to right, maroon, gold);
  color: white;
}
.m-div-1 p {
  font-size: larger;
}
.m-div-2 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  transform: skewX(2deg);
  padding: 2vw;
  background-image: linear-gradient(to left, maroon, gold);
  color: white;
}
.m-div-2 p {
  font-size: larger;
}
.m-div-3 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  transform: skewX(-2deg);
  padding: 2vw;
  background-image: linear-gradient(to right, maroon, gold);
  color: white;
}
.m-div-3 p {
  font-size: larger;
}
.num-div-inner p {
  color: #800000;
  font-weight: 1000;
  font-size: 2.2rem;
}
.infra-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  color: white;
  background-color: #800000;
  width: 100%;
  padding: 2vw;
  text-align: center;
}
.carder {
  box-shadow: 0 0 10px black;
  border: solid black;
  height: 30vh;
  width: 13vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3.5vh;
  background-color: white;
  color: black;
  gap: 2vh;
}
.carder:hover {
  background-color: #fcbb09;
}
.carder img {
  height: 15vh;
}
.carder p {
  font-size: 1.2rem;
  font-weight: 500;
}
.div-1-img {
  background-image: url(/public/assets/foot-team-2.jpeg);
  background-size: cover;
  background-position: center;
}
.div-2-img {
  background-image: url(/public/assets/foot-team-1.jpeg);
  background-size: cover;
  background-position: center;
}
.div-3-img {
  background-image: url(/public/assets/football-2.jpeg);
  background-size: cover;
  background-position: center;
}
.card-div {
  display: flex;
  gap: 2vw;
  padding: 5vw;
  flex-wrap: wrap;
  align-items: center;
}
@media (max-width: 600px) {
  .card-div {
    flex-direction: column;
    width: 40vh;
  }
  .carder {
    width: 35vh;
  }
}
.cards:hover {
  background-color: #e1e0e0;
}
.header-links ul {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  padding: 0;
  width: 80%;
}
.header-links li {
  list-style: none;
  transition: 0.3 ease-in-out;
}
.header-links a {
  transition: 0.3 ease-in-out;
  text-decoration: none;
  color: var(--grey);
  font-weight: 600;
}
.header-links a:hover {
  color: var(--red);
  text-decoration: underline;
}

.banner-1 {
  width: 100%;
  height: 700px;

  background-image: url(/public/assets/main-football.jpg);
  background-size: cover;
  background-position: center left;
  /* background-position-y: -400px; */

  background-repeat: no-repeat;
  display: flex;
  position: relative;
}
#imager {
  background-position: bottom;
  background-image: url(/public/assets/imager.jpg);
  background-size: cover;
}

.diagonal-div {
  width: 62%;
  height: 100%;
  min-width: 550px;
  max-height: 700px;
  background-color: var(--#800000);
  /* background: linear-gradient("#fff548", "#800000"); */
  /* background-color: linear-gradient(); */
  background-image: url(/public/assets/gold_bg.jpg);
  position: absolute;

  transform: skewX(-29deg);
  z-index: 2;
  transform-origin: top right;
}
.diagonal-div-2 {
  width: 43%;
  height: 40%;
  max-height: 700px;
  background-color: var(--grey);
  background-image: url(/public/assets/backBlack.png);
  position: absolute;
  transform: skewX(30deg);
  transform-origin: bottom;
  bottom: 0;
  z-index: 1;
}

.banner-text {
  display: flex;
  flex-direction: column;
  z-index: 3;
  position: relative;
  width: 37%;
  gap: 20px;
  margin-top: -10vh;
  color: white;
  justify-content: center;
  margin-left: 6%;
  overflow: hidden;
}
button {
  background-color: black;
  padding: 26px;
  padding-top: 8px;
  font-weight: 900;
  width: max-content;
  padding-bottom: 8px;
  color: white;
  border-radius: 50px;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  outline: none;
}
button:hover {
  background-color: white;
  color: black;
}
.banner-heading-1 {
  font-size: 3rem;
}
.banner-heading-1 span {
  opacity: 0;
  transform: translateY(20px);
  animation: emerge 1s forwards;
}

.banner-heading-1 span:nth-child(1) {
  animation-delay: 0.4s;
}

.banner-heading-1 span:nth-child(2) {
  animation-delay: 0.8s;
}

.banner-heading-1 span:nth-child(3) {
  animation-delay: 1.3s;
}

.banner-heading-1 span:nth-child(4) {
  animation-delay: 1.8s;
}

.banner-heading-1 span:nth-child(5) {
  animation-delay: 5s;
}
.banner-heading-2 span {
  font-size: 3.9rem;
  opacity: 0;
  transform: translateX(20px);
  animation: emerge 1s forwards;
}

.banner-heading-2 span:nth-child(1) {
  animation-delay: 2.1s;
}

.banner-heading-2 span:nth-child(2) {
  animation-delay: 2.7s;
}

.banner-heading-2 span:nth-child(3) {
  animation-delay: 3.5s;
}

.banner-heading-2 span:nth-child(4) {
  animation-delay: 4s;
}

.banner-heading-2 span:nth-child(5) {
  animation-delay: 5s;
}

@keyframes emerge {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.banner-heading-2 {
  font-size: 3.9rem;
  font-weight: 900;
  /* -webkit-text-stroke: 2px white; */
}

.help-section {
  display: flex;
  padding: 30px;
  gap: 20px;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.help-section p,
span {
  font-size: 2rem;
  font-weight: bolder;
}

.help-container {
  display: flex;
  margin-top: 60px;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  gap: 10px;
}
.cards {
  display: flex;
  width: 300px;
  gap: 20px;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 0 20px black;
  padding: 10vh;
  background-color: #ffffff;
  border-radius: 10vh;
}
.cards h1 {
  font-size: 1.5rem;
  font-weight: bolder;
}
.cards p {
  font-size: 1rem;
  width: 80%;
  text-align: center;
  color: #a2a2a2;
  font-weight: 300;
}
.help-img {
  border-radius: 80%;
  height: 170px;
  width: 170px;
  background-color: #fcbb09;
  display: flex;
  justify-content: center;
  box-shadow: 0px 0px 30px rgba(165, 165, 165, 0.5);
  align-items: center;
  transition: 0.5s ease-in-out;
}
.help-img:hover {
  box-shadow: 0px 0px 30px #800000;
}
.help-img img {
  height: 150px;
  width: 150px;
  border-radius: 80%;
}

.skill-div {
  /* display: flex; */
  flex-direction: column;
  /* align-items: center; */
  height: 50vh;
  width: 20vw;
  min-width: 30vh;
  padding: 5vh;
  /* background-image: url(/public/assets/football-1.jpg); */
  /* background: hsla(0, 0%, 0%, 0.25); */
  background-color: rgba(9, 9, 9, 0.486);
  background-blend-mode: overlay;
  background-position: center;
  background-size: cover;
  box-shadow: 0 10px 10px black;
  /* background-color: gold; */
  border-radius: 50px;
  overflow: hidden;
  transition: transform 500ms ease;
  flex-wrap: wrap;
  padding: 4rem 0 0;
}
.skill-div:hover,
.skill-div:focus-within {
  transform: scale(1.05);
}
.skill-content {
  --padding: 1.5rem;
  padding: var(--padding);
  text-wrap: wrap;

  /* background: linear-gradient(
    hsl(0 0% 0% / 0),
    hsl(20 0% 0% / 0.3) 20%,
    hsl(0 0% 0% / 1)
  ); */
  /* width: 10vw; */
  color: rgb(255, 255, 255);
}
.skill-content h1 {
  font-size: larger;
}
.skill-content p {
  position: relative;
  margin-bottom: 2vh;
  /* color: #61dafb; */
  width: max-content;
}
.skill-div:hover .skill-content p::after,
.skill-div:focus-within .skill-content p::after {
  transform: scaleX(1);
  background: #ffffff;
  /* background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)); */
}
.skill-content p::after {
  content: "";
  position: absolute;
  height: 4px;
  width: calc(100% + var(--padding));
  left: calc(var(--padding) * -1);
  bottom: -2px;
  background: rgb(255, 255, 255);
  transform-origin: left;
  transition: transform 500ms ease;
  animation: underline 500ms;
}

@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
    transition-delay: 0ms !important;
  }
}
@media (hover) {
  .skill-content {
    transform: translateY(65%);
    transition: transform 500ms ease;
  }
  .skill-content > *:not(.skill-content p) {
    opacity: 0;
    transition: opacity 500ms linear;
  }

  .skill-div:hover .skill-content > *:not(.skill-content p),
  .skill-div:focus-within .skill-content > *:not(.skill-content p) {
    opacity: 1;
    transition-delay: 700ms;
  }

  .skill-div:hover .skill-content,
  .skill-div:focus-within .skill-content {
    transform: translateY(0);
    transition-delay: 500ms;
  }

  .skill-div:focus-within .skill-content {
    transition-duration: 0ms;
  }

  .skill-content p::after {
    transform: scaleX(0);
  }
}

.about-us {
  display: flex;
  margin-top: 60px;
  height: 70vh;
}
.about-us-1 {
  width: 50%;
  /* height: 50%; */
  height: 50vh;
  height: inherit;
  background-image: url(/public/assets/help3.png);
  background-size: cover;
  animation: slide infinite 20s;
}

@keyframes slide {
  0% {
    background-image: url(/public/assets/foot-team-1.jpeg);
  }
  33% {
    background-image: url(/public/assets/foot-team-2.jpeg);
  }
  66% {
    background-image: url(/public/assets/football-4.jpeg);
  }
  100% {
    background-image: url(/public/assets/foot-team-1.jpeg);
  }
}

.about-us-1:hover {
  background-image: url(/public/assets/help1.png);
}
.about-us-1 img {
  width: 100%;
  height: 100%;
  transition: ease-in 1s;
}
.about-us-1 img:hover {
  /* display: none; */
  /* display: none; */
  /* border: solid 20px; */
  scale: 1.1;
}

.about-us-2 {
  width: 50%;
  background-color: var(--red);
  background-image: url(/public/assets/gold_bg.jpg);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.about-text {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 80%;
  position: relative;
  gap: 20px;
  color: white;
  justify-content: center;
}
/* .about-heading-1 {
  font-size: 3rem;
} */
.about-heading-2 {
  /* transition: ; */
  /* transition: ease-in 0.5s; */
  font-size: 3rem;
  width: 100%;
  font-weight: 700;
  -webkit-text-stroke: 1px white;
}

.feature-section {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  justify-content: center;
  align-items: center;
}

.feature-section p,
span {
  font-size: 2rem;
  font-weight: bolder;
}

.featured-container {
  margin-top: 60px;
  padding: 2vh;
  height: auto;
  width: 85%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  overflow: scroll;
  gap: 50px;
  overflow: hidden;
  flex-wrap: nowrap;
  white-space: nowrap;
  transform: translate3d(0, 0, 0);
}
.featured-card {
  display: flex;
  flex-direction: column;
  width: 300px;
  justify-content: center;
  align-items: center;
  animation: move 10s linear infinite;
}
/* .featured-card:nth-child(1) {
  animation-delay: 0s;
}

.featured-card:nth-child(2) {
  animation-delay: 3.33s;
}

.featured-card:nth-child(3) {
  animation-delay: 6.66s;
} */

@keyframes move {
  100% {
    -webkit-transform: translateX(-100%);
  }
}

/* Pause anximation on hover */
.featured-container:hover .featured-card {
  animation-play-state: paused;
}
.feature-img {
  flex: 1;
  height: 40%;
  width: 100%;
  background-image: url(/public/assets/gold_bg.jpg);
  position: relative;
}
.feature-img img {
  height: 100%;
  width: 100%;
}
.feature-bar {
  width: 90%;
  height: 40px;
  background-color: white;
  border-radius: 100px;
  position: absolute;
  top: 87%;
  left: 5%;
  display: flex;
  justify-content: center;
}
.line {
  width: 87%;
  display: flex;
  height: 100%;
  justify-content: space-around;

  align-items: center;
}
.line p {
  font-size: 0.8rem;
  margin-left: 10px;
}
.line-1 {
  width: 30%;
  border-start-start-radius: 40px;
  border-end-start-radius: 40px;
  height: 10px;
  background-color: var(--red);
}
.line-2 {
  width: 70%;
  border-start-end-radius: 40px;
  border-end-end-radius: 40px;
  height: 10px;
  background-color: #a2a2a2;
}

.feature-text {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 180px;
  padding-bottom: 15px;
  background-color: rgb(92, 0, 0);
  /* background-image: url(/public/assets/gold_bg.jpg); */
}
.stats {
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
  width: 90%;
}
.stats p {
  color: white;
  font-size: 1rem;
}
.feature-heading {
  text-align: center;
  width: 80%;
  font-size: 1.2rem;
  font-weight: 900;
  word-spacing: -1px;
  color: white;
}

.testimonials {
  margin-top: 60px;
  width: 100%;
  background-color: var(--red);
  background-image: url(/public/assets/testimonials.png);
  height: max-content;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding-left: 50px;
}
.testimonials h1 {
  font-size: 2rem;
  color: white;
  -webkit-text-stroke: 2px white;
  font-weight: 900;
  text-align: center;
  margin-bottom: 30px;
}
.dialog {
  width: 80%;
  border-radius: 50px;
  padding: 15px;
  background-color: white;
  text-align: center;
  padding-top: 60px;
  padding-bottom: 60px;
  justify-content: space-around;
  text-align: center;
  position: relative;
  z-index: 3;
  margin: 0 auto;
}

.dialog p {
  margin-bottom: 30px;
  width: 80%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #a2a2a2;
  font-weight: 300;
  z-index: 3;
}

.dialog span {
  font-size: 1.5rem;
  z-index: 3;
  font-weight: bolder;
}

.triangle {
  width: 100px;
  height: 100px;
  position: absolute;
  bottom: 0;
  left: 32%;
  background-color: white;
  transform: rotate(-45deg);
  z-index: -10;
}
.test-photos {
  display: flex;
  margin-top: 10px;
  gap: 15px;
}
.iconT img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 7px solid white;
  transition: 0.5s ease-in-out;
}
.iconT img:hover {
  border: 7px solid var(--grey);
}

.volunteer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 120px;
  background-color: #fcbb09;
  padding: 5vh;
}

.volunteer h1 {
  color: var(--red);
  font-weight: 900;
}
.volunteer p {
  color: black;
  font-weight: 900;
  font-size: 1.2rem;
}

.volunteer p.title {
  color: #2e2e2f;
  font-size: 2rem;
}

footer {
  width: 100%;

  background-color: var(--blue);
  height: max-content;
  padding-bottom: 30px;
  position: relative;
}
.contact {
  width: 80%;
  position: absolute;
  height: 100px;
  background-image: url(/public/assets/gold_bg.jpg);
  left: 10%;
  top: -50px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}
.contact i:first-child {
  font-size: 3rem;
}
.contact P.question {
  font-size: 1.2rem;
  width: 25%;
}
.contact P.number {
  font-size: 2.5rem;
  -webkit-text-stroke: 1.4px var(--white);
  word-spacing: 10;
}

.contact-1 {
  display: flex;
  width: 50%;
  flex: 1;
  align-items: center;
  justify-content: space-between;
}
.contact-2 {
  display: flex;
  flex: 1;
  width: 50%;
  justify-content: flex-end;
  gap: 30px;
}
.contact-2 div {
  width: 50%;
  position: relative;
}
.contact-2 input {
  width: 100%;
  padding: 7px;
  padding-left: 35px;
  border-radius: 100px;
}

#mail {
  position: absolute;
  top: 50%;
  font-size: 20px;
  color: gray;
  left: 10px;
  transform: translateY(-65%);
  position: absolute;
}

.footer {
  width: 80%;
  display: flex;
  margin: 0 auto;
}
.footer-content {
  margin-top: 150px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 30px;

  align-items: baseline;
  justify-content: space-around;
  color: white;
}
.footer-about {
  width: 30%;
  min-width: 250px;
}
.footer-about h1 {
  font-size: 1.5rem;
  margin-bottom: 13px;
  font-weight: bolder;
}
.footer-links {
  min-width: 150px;
}
.footer-links-ul {
  list-style-type: none;
}
.footer-links li {
  padding: 5px;
}
h2.links {
  justify-content: flex-start;
}
.gallery {
  display: flex;
  flex-direction: column;
}
.gal-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  padding: 0;
}
.gal-container div {
  display: flex;
}
.gal-container img {
  margin: 2px;
  width: 80px;
  height: 80px;
}

.contact-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
}
.contact-footer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contact-heading {
  display: flex;

  align-items: center;
  gap: 10px;
  width: 100%;
}
.location {
  width: 30px;
  height: 30px;
  background-color: #fcbb09;
  border-radius: 50%;
  color: white;
  display: flex;
  position: relative;
}
i.one {
  position: absolute;
  font-size: 20px;
  top: 13%;
  left: 30%;
}
i.two {
  position: absolute;
  font-size: 20px;
  top: 14%;
  left: 23%;
}
i.three {
  position: absolute;
  font-size: 18px;
  top: 16%;
  left: 20%;
}
.contact-heading h3 {
  color: #fcbb09;
  font-size: 1rem;
}

.contact-card {
  display: flex;
  flex-direction: column;
  border-bottom: 4px dotted rgb(84, 84, 84);
  padding-top: 15px;
  padding-bottom: 20px;
}
.contact-card:last-child {
  border-bottom: none;
}
.contact-ad {
  width: 80%;
  font-size: 1rem;
  margin-left: 45px;
  float: right;
  justify-content: flex-end;
}

.copyright {
  position: absolute;
  bottom: 20px;

  text-align: center;
  margin-top: 30px;
  color: white;
}

/* global */
li {
  cursor: pointer;
}

/* about us page */
#about-banner {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(/public/assets/main-football.jpg);
}
#mile {
  background-image: url(/public/assets/back.png);
}

#causes-banner {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(/public/assets/main-football-2.jpg);
}

#event1 {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.6),
    rgba(255, 255, 255, 0.6)
  );
  /* url(/public/assets/causes-banner.jpg); */
}
#event2 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(/public/assets/causes-banner.jpg);
}
#event3 {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    url(/public/assets/causes-banner.jpg);
}
.border-div {
  border: solid gold 5px;
  color: maroon;
  width: 80%;
  padding: 4vh;
}
.border-div h1 {
  font-size: 25px;
}
.banner-heading-3 {
  font-size: 1.5rem;
}

/* responsive */

@media screen and (max-width: 1024px) {
  .links {
    display: flex;
    justify-content: space-evenly;
    margin-right: 3%;
  }

  header {
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    /* position: sticky; */
    z-index: 50;
    height: 80px;

    /* margin-top: 1rem; */
  }
  .logo-container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-left: 1%;
    align-items: center;
  }
  .logo {
    width: 50px;
    height: 50px;
  }
  .logo-text {
    display: flex;
    flex-direction: column;
  }
  .featured-container {
    margin-top: 60px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* gap: 20px;s */
    flex-wrap: wrap;
  }
  .logo-img {
    height: 10px;
    width: 2vw;
    background-color: #61dafb;
    display: none;
  }
  .logo-text p {
    font-size: 1.2rem;
    font-weight: 600;
  }
  .logo-header {
    letter-spacing: -1px;
    font-size: 2rem;
    color: var(--red);
    font-weight: 1000;
  }
  .header-links {
    width: 60%;
  }
  .header-links ul {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 15px;

    width: 100%;
  }
  .header-links li {
    list-style: none;
  }
  .header-links a {
    text-decoration: none;
    color: var(--grey);
    font-weight: 600;
  }
  .banner-1 {
    height: inherit;
  }
  .banner-text {
    display: flex;
    flex-direction: column;
    z-index: 3;
    position: relative;
    width: 50%;
    margin-top: 1%;
    gap: 20px;
    color: white;
    justify-content: center;
    margin-left: 6%;
  }

  .contact {
    width: 100%;
    position: static;
    left: 0;
  }
  .contact i:first-child {
    font-size: 2rem;
  }
  .logo-text {
    height: 8vh;
    width: 8vh;
    background-image: url(/public/assets/logo-2.jpg);
    background-size: cover;
    background-position: center;
    border-radius: 40vh;
    /* background-color: aqua; */
  }
  .contact P.question {
    font-size: 1rem;
  }
  .contact P.number {
    font-size: 2rem;
    -webkit-text-stroke: 1.4px var(--white);
    word-spacing: 10;
  }
}

@media screen and (max-width: 786px) {
  * {
    font-size: 13px;
  }
  .links {
    display: flex;
    justify-content: space-evenly;
    margin-right: 5%;
  }
  header {
    display: block;
    justify-content: space-between;
    margin-left: auto;
    position: relative;
    align-items: baseline;
    margin-right: auto;
    position: sticky;
    z-index: 50;
    height: 70px;
    margin-bottom: 0;
  }

  .logo-container {
    display: flex;
    justify-content: flex-start;
    /* background-color: #61dafb; */
    height: 2vh;
    width: 3vh;
    margin-top: 17px;
    margin-left: 3%;
    align-items: center;
  }
  .logo-text {
    height: 8vh;
    width: 8vh;
    background-image: url(/public/assets/logo-2.jpg);
    background-size: cover;
    background-position: center;
    border-radius: 40vh;
    /* background-color: aqua; */
  }

  .logo {
    width: 50px;
    height: 50px;
  }
  .logo-text {
    display: flex;
    flex-direction: column;
  }
  .logo-text p {
    font-size: 1.2rem;
    font-weight: 600;
  }
  .logo-header {
    letter-spacing: -1px;
    font-size: 2rem;
    color: var(--red);
    font-weight: 1000;
  }
  .header-links {
    display: none;
    margin-top: 0;
  }
  .skill-div {
    height: 40vh;
    margin-top: 1.5vh;
  }
  .header-links ul {
    margin-top: 0;
  }
  .ham-btn {
    display: inline-block;
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
  }
  .ham-btn i {
    font-size: 1.5rem;
  }
  .active {
    display: block;

    width: 100%;
    height: 90vh;
    overflow: auto;
    background-color: var(--grey);
    color: #ffff;
    transition: background-color 1s ease-in-out;
  }
  .header-links ul {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    /* margin-top: 15px; */
    gap: 10px;

    flex-direction: column;

    width: 100%;
  }
  #tabs {
    height: fit-content;
  }
  .header-links Link {
    width: 100%;
  }
  .header-links li {
    list-style: none;
    text-align: center;
    width: 100%;
    z-index: 10;
    padding: 30px;
    border: 1px solid white;
  }
  .header-links li:hover {
    background-color: white;
    border: 1px solid white;
  }
  .header-links li:hover a {
    color: var(--red);
    border: 1px solid white;
  }

  .header-links a {
    text-decoration: none;
    color: white;
    font-weight: 600;
    font-size: 1.5rem;
  }
  .diagonal-div {
    width: 70%;
    min-width: 100px;
  }
  .banner-1 {
    background-image: linear-gradient(rgba(53, 53, 53, 0.5), rgba(0, 0, 0, 0.5)),
      url(/public/assets/football-1.jpg);

    /* background-position: center; */
    /* background-position: left; */
  }
  .banner-text {
    display: flex;
    flex-direction: column;
    z-index: 3;
    position: relative;
    width: 80%;
    gap: 20px;
    color: white;
    margin-top: -10;
    margin-left: 6%;
  }
  .about-us-1 {
    display: none;
  }
  .about-us-1 img {
    width: 100%;
    height: 100%;
  }
  .about-us-2 {
    width: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url(/public/assets/foot-team-2.jpeg);

    background-color: var(--red);
    display: flex;
    background-position: center;
    background-size: cover;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  .skill-div {
    padding: 0;
  }
  .help-container {
    display: flex;
    margin-top: 0px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .about-text {
    display: flex;
    flex-direction: column;
    width: 95%;
    position: relative;
    gap: 20px;
    color: white;
    justify-content: flex-start;
  }
  .featured-container {
    margin-top: 60px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* gap: 20px;s */
    flex-wrap: wrap;
  }

  .triangle {
    width: 100px;
    height: 100px;
    position: absolute;
    bottom: 0;
    left: 20%;
    background-color: white;
    transform: rotate(-45deg);
    z-index: -10;
  }
  .dialog img:first-child {
    position: absolute;
    top: 20%;
    left: 10px;
  }

  .contact {
    flex-direction: column;
    justify-content: space-between;
    height: max-content;
  }
  .contact-1 {
    margin-bottom: 10px;
    width: 80%;
  }
  .contact-1 p.question {
    min-width: 100px;
  }
  #mail {
    display: none;
  }
  .contact-2 input {
    width: 100%;
    min-width: 150px;
    padding: 7px;

    border-radius: 100px;
  }
  .contact-2 {
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
    width: 90%;
  }

  .contact-2 button {
    min-width: 100px;
    height: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 35px;
  }
  .footer-content {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 30px;
    flex: 1;

    align-items: baseline;
    justify-content: space-around;
    color: white;
  }
  .footer-about {
    width: 80%;
    min-width: 250px;
    text-align: center;
  }
  .footer-links {
    width: 80%;
    /* text-align: center; */
  }
  .footer-links-ul {
    display: none;
  }
  .gallery {
    width: 50%;
    text-align: center;
    justify-content: center;
  }
  .gal-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .active-links {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .links {
    text-align: center;
    margin-left: 20px;
  }
  .contact-footer {
    width: 80%;
  }
  .contact-container {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .contact-footer h2 {
    text-align: center;
  }
  .contact-card {
    border-bottom: none;
    margin-left: 30%;
  }
  .contact-heading {
    margin-bottom: 20px;
  }
  .contact-ad {
    margin-left: 0;
  }
  h2.links {
    justify-content: center;
  }
}

@media screen and (max-width: 480px) {
  * {
    font-size: 12px;
  }
  nav {
    justify-content: center;
    align-items: center;
  }

  .links {
    margin-right: 0%;
    padding: 0;
    width: 100%;
    justify-content: space-evenly;
  }
  .links i {
    margin: auto;
  }
  .diagonal-div {
    width: 100%;
    min-width: 100px;
  }
  .banner-1 {
    height: 550px;
    justify-content: flex-start;
  }
  .banner-text {
    display: flex;
    flex-direction: column;
    z-index: 3;
    position: relative;
    width: 90%;
    gap: 20px;

    color: white;
    justify-content: flex-start;

    margin-left: 6%;
    /* margin-top: 20%; */
  }

  .dialog img:first-child {
    position: absolute;
    top: 20%;
    left: 1px;
  }
  .dialog p {
    margin-right: 20px;
  }
  .display {
    display: none;
  }
  .test-photos img:first-child {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    border: 4px solid white;
  }
  .contact {
    height: max-content;
  }
  .contact-1 {
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-self: center;
  }
  .contact-1 p.question {
    width: 100%;
    text-align: center;
  }
  .contact-2 {
    margin-top: 20px;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    width: 90%;
  }
  .contact-2 div {
    min-width: 250px;
    width: 80%;
  }
  .links {
    text-align: center;
    margin-left: 0;
  }
  .contact-card {
    border-bottom: none;
    margin-left: 20%;
  }
  .contact-container {
    display: none;
  }
  .contact-container-active {
    display: flex;
  }
  .gal-btn {
    margin-bottom: 20px;
  }
  .gal-container {
    display: none;
  }
  .gallery {
    width: 80%;
  }
  .gal-container-active {
    display: flex;
    flex-direction: column;
  }
  .contact-btn {
    margin-bottom: 40px;
  }
  .border-div h1 {
    font-size: 18px;
  }
  .banner-heading-3 {
    width: 80%;
    font-size: small;
  }
  .m-div-1 {
    transform: skew(0);
    width: 90vw;
  }
  .m-div-2 {
    transform: skew(0);
    width: 90vw;
  }
  .m-div-3 {
    transform: skew(0);
    width: 90vw;
  }
}
